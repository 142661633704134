import "./App.css";
import useTelegram from "../shared/hooks/useTelegram";
import React, {useEffect, useState} from "react";
import "../shared/assets/styles/fonts.css";
import AppRoutes from "./routes/AppRoutes";
import {initializeApp} from "./actions/initializeApp";
import {useDispatch, useSelector} from "react-redux";
import LoadingPage from "../shared/components/LoadingPage";
// import {selectCurrentLanguage} from "../entities/Language/model/languageSelectors";
import {setLanguage as setTranslatorLanguage} from "../shared/utils/translator";
import CheckInPage from "../pages/CheckInPage";
import InstructionPage from "../pages/InstructionPage";
import {
    selectUserIsFirstLogin,
    selectUserIsFirstLoginToday,
    selectUserLocale
} from "../entities/User/model/userSelectors";

// Function to clear service worker cache and reload the page
const clearCacheAndReload = () => {
    // Check if the cache has already been cleared
    const isCacheCleared = localStorage.getItem('cacheCleared');

    if (!isCacheCleared && 'serviceWorker' in navigator) {
        // Clear service workers
        navigator.serviceWorker.getRegistrations().then(function (registrations) {
            for (let registration of registrations) {
                registration.unregister();
            }
        });

        // Clear caches
        caches.keys().then(function (names) {
            for (let name of names) {
                caches.delete(name);
            }
        });

        // Set a flag in localStorage to indicate that the cache has been cleared
        localStorage.setItem('cacheCleared', 'true');

        // Reload the page after clearing cache
        window.location.reload();
    }
};

export default function App() {
    const {onReady, onExpand, initData, onSetHeaderColor, disableVerticalSwipes} = useTelegram();
    const [isLoading, setIsLoading] = useState(true);
    const [isCheckIn, setIsCheckIn] = useState(true);
    const [isInstruction, setIsInstruction] = useState(true);
    const [invite, setInvite] = useState(null);
    const dispatch = useDispatch();
    const isFirstLoginToday = useSelector(selectUserIsFirstLoginToday);
    const isFirstLogin = useSelector(selectUserIsFirstLogin);
    const language = useSelector(selectUserLocale);

    useEffect(() => {
        const checkPath = () => {
            const path = window.location.pathname;
            console.log("Path window.location.pathname", path);
            if (path.startsWith('/miniapp')) {
                console.log("Path window.location.pathname", path);
                const inviteCode = path.replace('/miniapp', '');
                console.log("inviteCode", inviteCode);
                setInvite(inviteCode);
            } else {
                setInvite("")
            }
        };

        checkPath();

        const handlePopState = () => {
            checkPath();
        };

        window.addEventListener('popstate', handlePopState);

        window.addEventListener('hashchange', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
            window.removeEventListener('hashchange', handlePopState);
        };
    }, []);

    console.log("Environment: ", process.env.REACT_APP_ENVIRONMENT);

    console.log("initData", initData);

    useEffect(() => {
        // prepare telegram data
        onReady();
        // expand app to full screen
        onExpand();
        // set Telegram Mini App Header color
        onSetHeaderColor('#FFF');
        // disables vertical swipes to close or minimize the Mini App
        disableVerticalSwipes();

        // Clear cash in QA environment
        // if (process.env.REACT_APP_ENVIRONMENT === "QA_ENVIRONMENT"){
        //     clearCacheAndReload();
        // }
    }, []);

    useEffect(() => {
        console.log("setTranslatorLanguage: ", language);
        setTranslatorLanguage(language);
    }, [language]);

    useEffect(() => {
        const fetchData = async () => {
            console.log("App invite: ", invite);
            await dispatch(initializeApp(initData, invite));

            const timer = setTimeout(() => {
                setIsLoading(false);
            }, 1500);
            return () => clearTimeout(timer);
        };
        if (invite !== null) {
            fetchData();
        }

    }, [dispatch, invite]);

    if (isLoading) {
        return <LoadingPage />;
    }

    if (isFirstLoginToday) {
        if (isCheckIn) {
            return <CheckInPage handleClick={() => setIsCheckIn(false)}/>
        }
    }

    if (isFirstLogin) {
        if (isInstruction) {
            return <InstructionPage handleClick={() => setIsInstruction(false)} />
        }
    }
    return (
        <main className="App">
            <div className="container app-container">
                <AppRoutes />
            </div>
        </main>
  );
}
