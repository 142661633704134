import {
    SET_QUEST, SET_ANSWER
} from './questActions';

const initialState = {
    quest: null,
    answer: null
};

const questReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_QUEST:
            return {
                ...state,
                quest: action.payload,
            };
        case SET_ANSWER:
            return {
                ...state,
                answer: action.payload
            }
        default:
            return state;
    }
};

export default questReducer;
