import "./style.css";
import {useDispatch, useSelector} from "react-redux";
import {selectUserLocale, selectUserTimezone} from "../../entities/User/model/userSelectors";
import {useState} from "react";
import ToastMessage from "../../shared/components/ToastMessage";
import {setLanguage, translate} from "../../shared/utils/translator";
import useTelegram from "../../shared/hooks/useTelegram";
import {setUserLanguage} from "../../entities/User/model/userActions";
const SettingsPage = () => {
    const dispatch = useDispatch();
    const {onClose} = useTelegram();
    // const nickname = useSelector(selectUserName);
    const timeZone = useSelector(selectUserTimezone);
    const locale = useSelector(selectUserLocale);
    const [selectedLanguage, setSelectedLanguage] = useState(locale);

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const handleShowToast = (message = translate("in-development")) => {
        setToastMessage(message);
        setShowToast(true);
    };

    const handleCloseToast = () => {
        setShowToast(false);
    };

    const handleChangeLanguage = (language) => {
        dispatch(setUserLanguage(language));
        setLanguage(language);
        setSelectedLanguage(language);
    };

    return (
        <section className="settings-page-container no-scrollbar">
            <h1 className="settings-title">{translate("settings-title")}</h1>
            <div className="time-settings-container">
                <p>{translate("your-locale-timezone")}</p>
                <h4>{timeZone}</h4>
            </div>
            <div className="language-settings-container">
                <p>{translate("settings-language")}</p>
                <h4>
                    <span className={`language-option ${selectedLanguage === "ru" && "selected-language"}`} onClick={() => handleChangeLanguage("ru")}>
                        ru
                    </span>
                    &nbsp;
                    <span className={`language-option ${selectedLanguage === "en" && "selected-language"}`} onClick={() => handleChangeLanguage("en")}>
                        en
                    </span>
                </h4>
            </div>
            <div className="buttons-container">
                <button className="change-telegram-account" onClick={() => handleShowToast()}>
                    {translate("change-telegram-account")}
                </button>
                <button className="connect-x-button" onClick={() => handleShowToast()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#FFF" className="bi bi-twitter-x" viewBox="0 0 16 16">
                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                    </svg>
                    {translate("settings-connect-x")}
                </button>
                <button className="connect-discord-button" onClick={() => handleShowToast()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#FFF" className="bi bi-discord" viewBox="0 0 16 16">
                        <path d="M13.545 2.907a13.2 13.2 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.2 12.2 0 0 0-3.658 0 8 8 0 0 0-.412-.833.05.05 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.04.04 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032q.003.022.021.037a13.3 13.3 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019q.463-.63.818-1.329a.05.05 0 0 0-.01-.059l-.018-.011a9 9 0 0 1-1.248-.595.05.05 0 0 1-.02-.066l.015-.019q.127-.095.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.05.05 0 0 1 .053.007q.121.1.248.195a.05.05 0 0 1-.004.085 8 8 0 0 1-1.249.594.05.05 0 0 0-.03.03.05.05 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.2 13.2 0 0 0 4.001-2.02.05.05 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.03.03 0 0 0-.02-.019m-8.198 7.307c-.789 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612m5.316 0c-.788 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612"/>
                    </svg>
                    {translate("settings-connect-discord")}
                </button>
                <button className="connect-ton-button" onClick={() => handleShowToast()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#FFF" className="bi bi-wallet" viewBox="0 0 16 16">
                        <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a2 2 0 0 1-1-.268M1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1"/>
                    </svg>
                    {translate("settings-connect-ton-wallet")}
                </button>
                <button className="connect-env-button" onClick={() => handleShowToast()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 32 32" fill="none">
                        <path d="M16 31C24.2843 31 31 24.2843 31 16C31 7.71573 24.2843 1 16 1C7.71573 1 1 7.71573 1 16C1 24.2843 7.71573 31 16 31Z" fill="#627EEA"/>
                        <path d="M16.4675 4.74792V13.0639L23.496 16.205L16.4675 4.74792Z" fill="white" fillOpacity="0.602"/>
                        <path d="M16.4674 4.74792L9.43896 16.205L16.4674 13.0639V4.74792Z" fill="white"/>
                        <path d="M16.4675 21.5933V27.2439L23.5001 17.5132L16.4675 21.5933Z" fill="white" fillOpacity="0.602"/>
                        <path d="M16.4674 27.2439V21.5933L9.43896 17.5132L16.4674 27.2439Z" fill="white"/>
                        <path d="M16.4675 20.2851L23.496 16.205L16.4675 13.064V20.2851Z" fill="white" fillOpacity="0.2"/>
                        <path d="M9.43896 16.205L16.4674 20.2851V13.064L9.43896 16.205Z" fill="white" fillOpacity="0.602"/>
                    </svg>
                    {translate("settings-connect-ethereum")}
                </button>
                <button className="connect-youtube-button" onClick={() => handleShowToast()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#FFF" className="bi bi-youtube" viewBox="0 0 16 16">
                        <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"/>
                    </svg>
                    {translate("settings-connect-youtube")}
                </button>
            </div>
            <button className="settings-exit" onClick={() => onClose()}>
                {translate("settings-exit")}
            </button>
            <ToastMessage
                text={toastMessage}
                duration={3000}
                show={showToast}
                onClose={handleCloseToast} />
        </section>
    )
}

export default SettingsPage;
