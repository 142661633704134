import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import "./style.css";
import DOMPurify from 'dompurify';
import CMLogo from "../../shared/assets/images/Logo Small.jpg"
import {translate} from "../../shared/utils/translator";

function InstructionPage({handleClick}) {
    const [currentSlide, setCurrentSlide] = useState(0);

    const slides = [
        { id: 1, contentTop: "", content: translate("instructions-page-slide-first-content"),  image: CMLogo},
        { id: 2, contentTop: "", content: translate("instructions-page-slide-second-content"),  image: CMLogo},
        { id: 3, contentTop: "", content: translate("instructions-page-slide-third-content"),  image: CMLogo},
        { id: 4, contentTop: "", content: translate("instructions-page-slide-fourth-content"),  image: CMLogo},
        { id: 6, contentTop: "", content: translate("instructions-page-slide-fifth-content"),  image: CMLogo},
        { id: 5, contentTop: "", content: translate("instructions-page-slide-sixth-content"),  image: CMLogo},
    ];

    const handleNext = () => {
        if (currentSlide < slides.length - 1) {
            setCurrentSlide(currentSlide + 1);
        } else {
            handleClick();
        }
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => handleNext(),
        onSwipedRight: () => {
            if (currentSlide > 0) {
                setCurrentSlide(currentSlide - 1);
            }
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });

    const handleSlideDotClick = (index) => {
        console.log(`Index: ${index}`);
        setCurrentSlide(index);
    }

    const sanitizedContent = DOMPurify.sanitize("<p " + slides[currentSlide].content + "</p>");

    return (
        <div className="slide-show" {...handlers}>
            {/*<div className="close-button" onClick={handleClick}>*/}
            {/*    &times;*/}
            {/*</div>*/}
            <div className="slide">
                <h4>{slides[currentSlide].contentTop}</h4>
                {/*<div className="slide-image-container">*/}
                    <img src={slides[currentSlide].image} alt="" />
                {/*</div>*/}
                <div
                    dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                />
                <button onClick={handleNext} className="next-button">
                    {currentSlide === slides.length - 1 ? translate("instruction-page-button-close") : translate("instruction-page-button-next")}
                </button>
            </div>
            <div className="slide-dots-container">
                {slides.map((item, index) => (
                    <span className={`slide-dot ${currentSlide === index && "current"}`} key={index} onClick={() => handleSlideDotClick(index)}></span>
                ))}
            </div>
        </div>
    );
}

export default InstructionPage;
