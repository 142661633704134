import React, { useEffect, useState } from "react";
import "./style.css";
import {translate} from "../../../shared/utils/translator";
import Modal from "../../../pages/NewsPage/components/Modal";
import {markAsReadNews} from "../model/taskActions";
import {useDispatch} from "react-redux";
import useTelegram from "../../../shared/hooks/useTelegram";

const extractVideoId = (url) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/.*[?&]v=([^&]+)|youtu\.be\/([^?&]+)/;
    const match = url.match(regex);
    return match ? (match[1] || match[2]) : null;
};

const NewsItem = ({ news, index }) => {
    const dispatch = useDispatch();
    const {onOpenLink} = useTelegram();
    const content = news.content;
    const createdAt = news['created-at'];
    const expiredDate = news['expired-date'];
    const id = news.id;
    const isRead = news['is-read'];
    // const title = news.title;
    const link = news.link;
    const imageLink = news["image-link"];


    const createdAtPart = createdAt.split('T')[0];

    const [image, setImage] = useState(null);
    const [video, setVideo] = useState(null);
    const [contentLink, setContentLink] = useState(null);
    useEffect(() => {
        console.log(`Id: ${id} link: `, link);
        if (!link) {
            return;
        }
        if (link.startsWith("https://www.youtube.com/watch")) {
            const videoId = extractVideoId(link);
            setVideo(`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`);
        } else {
            setContentLink(link);
        }
        if (imageLink) {
            setImage(imageLink);
        }
    }, [])

    const awards = 100;

    const [userStatus, setUserStatus] = useState(isRead ? "DONE" : "START");

    const [timeRemaining, setTimeRemaining] = useState("999:99:99");
    const [showFullText, setShowFullText] = useState(false);
    // const [isLoading, setIsLoading] = useState(userStatus === "PENDING");

    const wordLimit = 36; // Approximately 6 lines worth of text
    const words = content.split(" ");
    const isContentTruncated = words.length > wordLimit;
    useEffect(() => {
        // Function to calculate the time difference
        const calculateTimeLeft = () => {
            const now = new Date();
            const targetDate = new Date(expiredDate + "Z");

            // Convert to timestamps in seconds
            const nowTimestamp = Math.floor(now.getTime() / 1000);
            const targetTimestamp = Math.floor(targetDate.getTime() / 1000);

            const difference = targetTimestamp - nowTimestamp;

            if (difference <= 0) {
                setTimeRemaining('Expired');
            } else {
                const hours = Math.floor(difference / 3600);
                const minutes = Math.floor((difference - hours * 3600) / 60);
                const seconds = Math.floor(difference - hours * 3600 - minutes * 60);

                // Format the time to always have 2 digits
                const formattedTime =
                    String(hours).padStart(3, '0') + ':' +
                    String(minutes).padStart(2, '0') + ':' +
                    String(seconds).padStart(2, '0');

                setTimeRemaining(formattedTime);
            }
        };

        // Call the function to calculate the time immediately
        calculateTimeLeft();

        // Update the countdown every second
        const timerInterval = setInterval(calculateTimeLeft, 1000);

        // Clean up the interval on component unmount
        return () => clearInterval(timerInterval);
    }, [expiredDate]);

    const toggleText = () => setShowFullText(!showFullText);

    const [showModal, setShowModal] = useState(false);

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const handleButtonClick = () => {
        if (video && userStatus !== 'DONE') {
            toggleModal();
            return;
        }
        if (userStatus === 'START') {
            setUserStatus('PENDING');
            const markAsReadNewsResponse = dispatch(markAsReadNews(id));
            if (markAsReadNewsResponse) {
                setUserStatus('DONE');
            }
        }
    };

    return (
        <>
            <div className="news-header">{createdAtPart}</div>
            {image &&
                <div className="image-container" style={{height: `${150 + (25 * index)}px`}}>
                    <img src={image} alt=""/>
                    <p>{`${150 + (25 * index)}px`}</p>
                </div>
            }
            {video &&
                <Modal id={id} show={showModal} onClose={toggleModal} videoLink={video} timeTillEnd={5} updateUserStatus={setUserStatus} />
            }
            <div className={`news-content ${showFullText ? "expanded" : ""}`}>
                <p>
                    <span className="content-link" onClick={() => onOpenLink(contentLink)}>{contentLink}</span>
                {showFullText ?  " " + content : " " + words.slice(0, wordLimit).join(" ") + (isContentTruncated ? "..." : "")}
                {!showFullText && isContentTruncated && (
                    <span className="news-continue" onClick={toggleText}>continue</span>
                )}
                </p>
            </div>
            <div className={`news-footer ${userStatus !== "START" ? 'border-white' : ''}`} onClick={handleButtonClick}>
                <div className={`overlay ${userStatus !== "START" ? 'visible' : ''}`}></div>
                {userStatus === "START" && (
                    <>
                        <div className="button-status-section">
                            {video ? translate("button-status-section-news-item-video") : translate("button-status-section-news-item")}
                        </div>
                        <div className="button-main-section">
                        + {awards} CMP
                        </div>
                        <div className="button-timer-section">
                            <span>{timeRemaining}</span>
                        </div>
                    </>
                )}
                {userStatus === "PENDING" && (
                    <>
                        <div className="button-status-section">
                        </div>
                        <div className="button-main-section">
                            <div className="news-loading-spinner">
                                <div className="spinner"></div>
                            </div>
                        </div>
                        <div className="button-timer-section">
                        </div>
                    </>
                )}
                {userStatus === "DONE" && (
                    <>
                        <div className="button-status-section">
                        </div>
                        <div className="button-main-section">
                            <div className="news-completed-icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12" cy="12" r="12" fill="white" />
                                    <path d="M6 12L10 16L18 8" stroke="#6E8BF1" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        </div>
                        <div className="button-timer-section">
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default NewsItem;
