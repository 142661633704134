import "./style.css";
import {useDispatch, useSelector} from "react-redux";
import {
    selectStatistics, selectTotalPoints, selectUserCheckInConsecutiveDays,
    selectUserConsecutiveDays,
    selectUserLeaderBoardPlace, selectUserLevel, selectUserName,
    selectUserQuestConsecutiveDays,
    selectUserSecondLevelReferrals,
} from "../../entities/User/model/userSelectors";
import React, {useEffect, useState} from "react";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import {getStatistics} from "../../entities/User/model/userActions";
import {translate} from "../../shared/utils/translator";
import {getUserReferrals} from "../../entities/Referral/model/referralActions";
import {selectReferrals} from "../../entities/Referral/model/referralSelectors";
import useTelegram from "../../shared/hooks/useTelegram";
const StatisticsPage = () => {
    const {onOpenLink, onOpenTelegramLink} = useTelegram();
    const dispatch = useDispatch();
    // TEST MODE
    // const userData = {
    //     nickname: "JohnDoe",
    //     level: 5,
    //     check_in_consecutive_days: 3,
    //     consecutive_days: 7,
    //     quest_consecutive_days: 2,
    //     leader_board_place: 12,
    //     total_points: 1500,
    //     second_level_referrals: 5,
    //     total_users: 1000,
    //     new_users: 50,
    //     active_users: 200,
    //     total_user_points: 50000,
    //     average_user_points: 50,
    // };
    // TEST MODE

    const nickname = useSelector(selectUserName);
    const level = useSelector(selectUserLevel);
    const total_points = useSelector(selectTotalPoints);
    const check_in_consecutive_days = useSelector(selectUserCheckInConsecutiveDays);
    const consecutive_days = useSelector(selectUserConsecutiveDays);
    const quest_consecutive_days = useSelector(selectUserQuestConsecutiveDays);
    const leader_board_place = useSelector(selectUserLeaderBoardPlace);
    const second_level_referrals = useSelector(selectUserSecondLevelReferrals);
    const statistics = useSelector(selectStatistics);
    const referrals = useSelector(selectReferrals);

    const [loading, setLoading] = useState(false);  // Loading state

    useEffect(() => {
        const fetchStatisticsData = async () => {
            if (!statistics) {
                setLoading(true);  // Start loading
                await dispatch(getStatistics());
                await dispatch(getUserReferrals(0, 20));
                setLoading(false); // End loading whether success or failure
            }
        };

        fetchStatisticsData();
    }, [dispatch]);

    if (loading) {
        return (
            <LoadingSpinner />
        );
    }


    if (!statistics) {
        return (
            <section className="user-statistic-container">
                {translate("cannot-get-statistics")}
            </section>
        );
    }
    return (
      <section className="user-statistic-container no-scrollbar">
          <div className="user-profile-statistics-container">
              <h2>{translate("statistics-user-profile")}</h2>
              <p><strong>{translate("statistics-nickname")}:</strong> {nickname}</p>
              <p><strong>{translate("statistics-total-points")}:</strong> {total_points}</p>
              <p><strong>{translate("statistics-level")}:</strong> {level}</p>
              <p><strong>{translate("statistics-leaderboard-position")}:</strong> {leader_board_place}</p>
              <p><strong>{translate("statistics-first-level-referral")}:</strong> {referrals.totalElements}</p>
              <p><strong>{translate("statistics-second-level-referral")}:</strong> {second_level_referrals} </p>
              <p><strong>{translate("statistics-check-in-streak")}:</strong> {check_in_consecutive_days} days</p>
              <p><strong>{translate("statistics-quest-streak")}:</strong> {quest_consecutive_days} days</p>
              <p><strong>{translate("statistics-active-days")}:</strong> {consecutive_days} days</p>
          </div>
          <div className="miniapp-statistics-container">
              <h2>{translate("statistics-miniapp")}</h2>
              <p><strong>{translate("statistics-total-users")}:</strong> {statistics['total-users']}</p>
              <p><strong>{translate("statistics-new-users")}:</strong> {statistics['new-users']}</p>
              <p><strong>{translate("statistics-active-users")}:</strong> {statistics['active-users'] || 0}</p>
              <p><strong>{translate("statistics-total-user-points")}:</strong> {statistics['total-user-points'] || 0}</p>
              <p><strong>{translate("statistics-average-user-points")}:</strong> {statistics['average-user-points'] || 0}</p>
          </div>
          <div className="resources-container">
              <h2>{translate("statistics-resources")}</h2>
              <div className="icons-container">
                  <div className="telegram-ru-container">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="#6E8BF1" className="bi bi-telegram" viewBox="0 0 16 16" onClick={() => onOpenTelegramLink("https://t.me/collective_ru")}>
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09"/>
                      </svg>
                  </div>
                  <div className="telegram-en-container">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="#6E8BF1" className="bi bi-telegram" viewBox="0 0 16 16" onClick={() => onOpenTelegramLink("https://t.me/collective_en")}>
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09"/>
                      </svg>
                  </div>
                  <div className="twitter-x-container">
                      <div className="svg-container" onClick={() => onOpenLink("https://x.com/cm_ecosystem")}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" className="bi bi-twitter-x" viewBox="0 0 16 16">
                              <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                          </svg>
                      </div>
                  </div>
                  <div className="discord-container">
                      <div className="svg-container" onClick={() => onOpenLink("https://discord.gg/eTqabdpF6g")}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" className="bi bi-discord" viewBox="0 0 16 16">
                              <path d="M13.545 2.907a13.2 13.2 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.2 12.2 0 0 0-3.658 0 8 8 0 0 0-.412-.833.05.05 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.04.04 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032q.003.022.021.037a13.3 13.3 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019q.463-.63.818-1.329a.05.05 0 0 0-.01-.059l-.018-.011a9 9 0 0 1-1.248-.595.05.05 0 0 1-.02-.066l.015-.019q.127-.095.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.05.05 0 0 1 .053.007q.121.1.248.195a.05.05 0 0 1-.004.085 8 8 0 0 1-1.249.594.05.05 0 0 0-.03.03.05.05 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.2 13.2 0 0 0 4.001-2.02.05.05 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.03.03 0 0 0-.02-.019m-8.198 7.307c-.789 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612m5.316 0c-.788 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612"/>
                          </svg>
                      </div>
                  </div>
              </div>
              <div className="website-container">
                  <p onClick={() => onOpenLink("https://collective.money")}>https://collective.money</p>
              </div>
          </div>
          {/*<div className="user-profile-container">*/}
          {/*    <h2>{translate("statistics-user-profile")}</h2>*/}
          {/*    <p><strong>{translate("statistics-nickname")}:</strong> {nickname}</p>*/}
          {/*    <p><strong>{translate("statistics-level")}:</strong> {level}</p>*/}
          {/*    <p><strong>{translate("statistics-total-points")}:</strong> {total_points}</p>*/}
          {/*</div>*/}

          {/*<div className="engagment-stats-container">*/}
          {/*    <h2>{translate("statistics-engagement-stats")}</h2>*/}
          {/*    <p><strong>{translate("statistics-check-in-streak")}:</strong> {check_in_consecutive_days} days</p>*/}
          {/*    <p><strong>{translate("statistics-active-days")}:</strong> {consecutive_days} days</p>*/}
          {/*    <p><strong>{translate("statistics-quest-streak")}:</strong> {quest_consecutive_days} days</p>*/}
          {/*</div>*/}

          {/*<div className="leaderboard-container">*/}
          {/*    <h2>{translate("statistics-leaderboard")}</h2>*/}
          {/*    <p><strong>{translate("statistics-leaderboard-position")}:</strong> {leader_board_place}</p>*/}
          {/*</div>*/}

          {/*<div className="refferal-stats-container">*/}
          {/*    <h2>{translate("statistics-referral-stats")}</h2>*/}
          {/*    <p><strong>{translate("statistics-first-level-referral")}:</strong> {referrals.totalElements}</p>*/}
          {/*    <p><strong>{translate("statistics-second-level-referral")}:</strong> {second_level_referrals} </p>*/}
          {/*</div>*/}

          {/*/!* User Engagement Overview Section *!/*/}
          {/*<div className="user-engagment-overview-container">*/}
          {/*    <h2>{translate("statistics-user-engagement-overview")}</h2>*/}
          {/*    <p><strong>{translate("statistics-total-users")}:</strong> {statistics['total-users']}</p>*/}
          {/*    <p><strong>{translate("statistics-new-users")}:</strong> {statistics['new-users']}</p>*/}
          {/*    <p><strong>{translate("statistics-active-users")}:</strong> {statistics['active-users'] || 0}</p>*/}
          {/*    <p><strong>{translate("statistics-total-user-points")}:</strong> {statistics['total-user-points'] || 0}</p>*/}
          {/*    <p><strong>{translate("statistics-average-user-points")}:</strong> {statistics['average-user-points'] || 0}</p>*/}
          {/*</div>*/}
      </section>
    );
}

export default StatisticsPage;
